/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask, waitForProperty } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import window from 'ember-window-mock';

import {
  GMI_SOLUTION_INSTANCE_STATUSES,
  GMI_STATUSES_ACTION_REQUIRED,
  GMI_STATUSES_CONNECTING_STATUSES,
  GMI_STATUSES_FAILED_STATUSES,
  GMI_STATUSES_NO_PERMISSION_EVENTS,
} from 'qonto/constants/gmi-solution-instance';
import type GmiSolutionInstanceModel from 'qonto/models/gmi-solution-instance';
import type MembershipModel from 'qonto/models/membership';
import { Header } from 'qonto/react/components/connections/sidebar/header';

interface ConnectionsInvoicesSidebarSignature {
  // The arguments accepted by the component
  Args: {
    connection?: {
      id?: string;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConnectionsInvoicesSidebarComponent extends Component<ConnectionsInvoicesSidebarSignature> {
  header = Header;

  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];

  fetchGmiSolutionTask = restartableTask(async () => {
    if (!variation('feature--bolean-gmi-async-flow')) return;

    // @ts-expect-error
    await waitForProperty(this, 'args.connection.id');

    try {
      return await this.store.findRecord(
        'gmi-solution-instance',
        this.args.connection?.id as string,
        {
          reload: true,
        }
      );
    } catch {
      // ignore
    }
  });

  get membership(): MembershipModel {
    return this.organizationManager.membership;
  }

  get gmiSolutionInstance(): GmiSolutionInstanceModel | null {
    if (!this.args.connection?.id) return null;

    let gmiSolutionInstance = this.store
      .peekAll('gmi-solution-instance')
      .find(({ id }) => id === this.args.connection?.id);

    if (!gmiSolutionInstance) {
      gmiSolutionInstance = this.gmiSolutionTracked.value as GmiSolutionInstanceModel;
    }

    return gmiSolutionInstance;
  }

  @action
  reconnect() {
    if (this.disclaimer) {
      // @ts-expect-error
      this.popup = window.open(this.disclaimer.troubleshootingUrl, 'gmi', 'popup');
    }
  }

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.invoice-retrieval.item2'),
    ];
  }

  get disclaimerDetails() {
    let { gmiStatus, membership, name } = this.gmiSolutionInstance as GmiSolutionInstanceModel;
    switch (true) {
      case gmiStatus === GMI_SOLUTION_INSTANCE_STATUSES.ACCOUNT_NOT_READY:
        return {
          message: this.intl.t(
            'settings.connections.sidebar.connection-failure-account-not-ready',
            {
              GMIintegrationName: name,
            }
          ),
          level: 'error',
        };

      case GMI_STATUSES_NO_PERMISSION_EVENTS.includes(gmiStatus):
        return {
          message: this.intl.t(
            'settings.connections.sidebar.connection-failure-account-permissions',
            {
              GMIintegrationName: name,
            }
          ),
          level: 'error',
          buttonText: this.intl.t('settings.connections.sidebar.connect-again-cta'),
        };

      case GMI_STATUSES_FAILED_STATUSES.includes(gmiStatus):
        if (this.membership.id !== membership.get('id')) {
          return {
            message: this.intl.t('settings.connections.sidebar.connection-failed-contact-request', {
              name: membership.get('fullName'),
              GMIintegrationName: name,
            }),
            level: 'error',
            buttonText: this.intl.t('settings.connections.sidebar.connect-again-cta'),
          };
        }

        return {
          message: this.intl.t('settings.connections.sidebar.connection-failed'),
          level: 'error',
          buttonText: this.intl.t('settings.connections.sidebar.connect-again-cta'),
        };

      case GMI_STATUSES_CONNECTING_STATUSES.includes(gmiStatus):
        return {
          message: this.intl.t('settings.connections.sidebar.connection-in-progress'),
          level: 'info',
        };

      case GMI_STATUSES_ACTION_REQUIRED.includes(gmiStatus):
        if (this.membership.id !== membership.get('id')) {
          return {
            message: this.intl.t('settings.connections.sidebar.2fa-contact-request', {
              name: membership.get('fullName'),
              GMIintegrationName: name,
            }),
            level: 'warning',
            buttonText: this.intl.t('settings.connections.sidebar.authenticate-cta'),
          };
        }

        return {
          message: this.intl.t('settings.connections.sidebar.2fa-description'),
          level: 'warning',
          buttonText: this.intl.t('settings.connections.sidebar.authenticate-cta'),
        };

      default:
        return null;
    }
  }

  get disclaimer() {
    if (!variation('feature--bolean-gmi-async-flow')) return null;

    if (!this.gmiSolutionInstance || !this.disclaimerDetails) {
      return null;
    }

    return {
      message: this.disclaimerDetails.message,
      troubleshootingUrl: this.gmiSolutionInstance.troubleshootingUrl,
      level: this.disclaimerDetails.level,
      buttonText: this.disclaimerDetails.buttonText,
    };
  }

  gmiSolutionTracked = trackedTask(this, this.fetchGmiSolutionTask, () => [
    this.args.connection?.id,
  ]);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Invoices::Sidebar': typeof ConnectionsInvoicesSidebarComponent;
  }
}
