export default {
  "wrapper": "ps",
  "legend": "pF",
  "grid": "pg",
  "subtitle": "pD body-2",
  "fiscal-code": "pV",
  "personal-data-name": "pL",
  "personal-data-birth": "pT",
  "personal-data": "pz",
  "tax-residence": "pa",
  "payer-agent": "pH",
  "errors": "pO",
  "business": "pm",
  "address": "pp"
};
