export default {
  "header": "VX",
  "header__main": "VC",
  "note": "Vk",
  "review-status": "VJ",
  "reviewer-wrapper": "Vs",
  "reviewer": "VF",
  "decline-note": "Vg",
  "decline-note-title": "VD",
  "status-dot": "VV",
  "approved": "VL",
  "rejected": "VT"
};
