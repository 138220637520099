export default {
  "details": "gT",
  "top-details": "gz",
  "name": "ga",
  "account-infobox": "gH",
  "row": "gO",
  "icon": "gm",
  "account-number": "gp",
  "currency": "gx",
  "activity-tag": "gw",
  "activity-icon": "gN",
  "edit-btn": "gv",
  "action-buttons-container": "go"
};
