/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { Placeholder } from 'qonto/react/components/connections/table/placeholder';
import { Row } from 'qonto/react/components/connections/table/row';
import { Header } from 'qonto/react/connections/components/table/header';

interface TableSignature {
  Element: null;
  Args: {
    isSidebarOpen?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class Table extends Component<TableSignature> {
  header = Header;
  placeholder = Placeholder;
  row = Row;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Banks::Table': typeof Table;
  }
}
