export default {
  "card": "BG",
  "header": "Br",
  "budget-caption": "Bb caption",
  "amount-spent-section": "By",
  "amount-spent-icon-container": "BU",
  "amount-spent-title": "Bj title-3",
  "amount-budgeted-section": "BW",
  "amount-budgeted-title": "Bu title-3"
};
