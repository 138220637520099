export default {
  "container": "vo",
  "numbering": "vn",
  "sideview": "vi",
  "form": "vA",
  "numbering-fields": "vY",
  "row": "vh",
  "label-tooltip": "vf",
  "tooltip-icon": "vK",
  "legend": "vG",
  "next-invoice-number": "vr",
  "pdf-preview": "vb",
  "de-layout": "vy",
  "de-pdf-preview": "vU"
};
