export default {
  "container": "jO",
  "form-core": "jm",
  "label": "jp",
  "request-description": "jx",
  "radio-option": "jw",
  "radio-button": "jN",
  "inline-inputs": "jv",
  "placeholder-label": "jo",
  "placeholder-input": "jn",
  "placeholder-checkbox": "ji"
};
