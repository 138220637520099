export default {
  "header": "Nd",
  "header-top": "NI",
  "header-inner": "Nt",
  "disclaimer": "NM mb-16",
  "header-main-wrapper": "NP",
  "header-main": "Nl",
  "logo": "NX mr-16",
  "header-right": "NC",
  "partner-description": "Nk body-2"
};
