export default {
  "container": "NS",
  "header-wrapper": "Nc mb-16",
  "header": "Nq",
  "logo": "NZ mr-16",
  "title": "NR title-4",
  "description": "NQ body-2",
  "conditions": "Ne",
  "label": "NB caption mb-4",
  "tags": "NE"
};
