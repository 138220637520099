export default {
  "chip": "Jf",
  "chip--hidden": "JK",
  "chip--collapsed": "JG",
  "chip--selected": "Jr",
  "chip--disabled": "Jb",
  "wrapper": "Jy",
  "content": "JU body-1",
  "clear": "Jj",
  "clear-icon": "JW"
};
