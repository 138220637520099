export default {
  "quote-information-item": "cSa",
  "placeholder": "cSH",
  "divider": "cSO",
  "tooltip": "cSm",
  "amount": "cSp title-4",
  "align-left": "cSx",
  "sticky-panel": "cSw",
  "discount-wrapper": "cSN",
  "discount-amount": "cSv",
  "discount-amount-strikethrough": "cSo caption"
};
