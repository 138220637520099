export default {
  "wrapper": "WF",
  "confirm": "Wg",
  "plan-info": "WD",
  "plan-info-left": "WV",
  "info-title": "WL body-1",
  "initial-trial-content": "WT",
  "extra-fees-item": "Wz body-2",
  "extra-fees-container": "Wa",
  "bottom-wrapper": "WH",
  "total-price-container": "WO",
  "extra-fees-element": "Wm body-1",
  "border": "Wp",
  "subtitle": "Wx body-2",
  "group-item": "Ww",
  "amount": "WN",
  "subscription-billing-summary": "Wv",
  "summary": "Wo",
  "summary-list": "Wn",
  "disclaimer": "Wi",
  "disclaimer-revamp": "WA",
  "error": "WY",
  "error-link": "Wh",
  "warning-wrapper": "Wf",
  "warning-label": "WK body-1"
};
