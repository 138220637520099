export default {
  "card-sidebar-header": "tp",
  "content": "tx",
  "type": "tw",
  "type-container": "tN",
  "type-close": "tv",
  "type-status-awaiting": "to",
  "type-status-live": "tn",
  "type-status-paused": "ti",
  "type-status-ghost": "tA",
  "actions-panel": "tY",
  "actions-panel-header": "th",
  "action-btn": "tf",
  "disclaimer": "tK",
  "infobox": "tG",
  "btn-panel": "tr",
  "required-action-btn": "tb",
  "kyc-disclaimer": "ty"
};
