export default {
  "wrapper": "rp",
  "promotion-title": "rx",
  "comparison-table": "rw",
  "table-head": "rN",
  "title-wrapper": "rv",
  "illustration-wrapper": "ro",
  "illustration": "rn",
  "role-wrapper": "ri",
  "role-name": "rA",
  "role-disabled": "rY",
  "role-access": "rh",
  "disabled-caption": "rf",
  "table-head-sticky": "rK",
  "show-border": "rG",
  "group-row": "rr",
  "table-body": "rb",
  "group": "ry",
  "table-body-row": "rU",
  "feature-description": "rj body-2",
  "feature": "rW body-2",
  "group-spacer": "ru"
};
