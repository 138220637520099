export default {
  "wrapper": "Pw",
  "content-wrapper": "PN",
  "no-fee": "Pv",
  "concierge": "Po",
  "no-fee-item": "Pn",
  "concierge-item": "Pi",
  "no-fee-icon": "PA",
  "lounge-icon": "PY",
  "insurance-conditions": "Ph",
  "description": "Pf",
  "footer-detail": "PK",
  "title": "PG",
  "footer-price": "Pr",
  "footer-content": "Pb",
  "lounge-text": "Py",
  "lounge": "PU",
  "lounge-images": "Pj",
  "img": "PW",
  "footer-sticky-bar": "Pu",
  "visible": "lS",
  "content": "lc",
  "content-card": "lq"
};
