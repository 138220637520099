export default {
  "cell": "Ee caption-bold",
  "empty": "EB",
  "transaction": "EE",
  "member": "Ed",
  "method": "EI",
  "operation-date": "Et",
  "amount": "EM",
  "label": "EP caption-bold",
  "active": "El"
};
