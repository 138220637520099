export default {
  "row": "Ll",
  "cell": "LX body-2",
  "empty": "LC",
  "cell-content": "Lk",
  "cell-content--amount": "LJ body-1",
  "icon": "Ls",
  "description": "LF",
  "tertiary": "Lg",
  "details": "LD"
};
