export default {
  "wrapper": "DL",
  "label": "DT",
  "input": "Dz",
  "icon-trash": "Da",
  "hidden": "DH",
  "icon-color": "DO",
  "icon-edit": "Dm",
  "icon-edit-save": "Dp",
  "button-container": "Dx"
};
