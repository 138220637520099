export default {
  "item": "Hx",
  "qonto-pilot-nav-item": "Hw",
  "wrapper": "HN",
  "collapsed": "Hv",
  "label": "Ho body-1",
  "icon-wrapper": "Hn",
  "icon": "Hi",
  "key": "HA",
  "keys": "HY"
};
