export default {
  "header": "PQ",
  "hidden": "Pe",
  "header-inline": "PB",
  "header-inline__search-bar": "PE",
  "header-bottom": "Pd",
  "margin-top": "PI",
  "header-bottom-loading": "Pt",
  "header-bottom-loading-state": "PM",
  "actions__filters": "PP",
  "actions__filters__toggle-btn": "Pl",
  "tabs": "PX",
  "header-inline__filters": "PC",
  "filter-ml": "Pk",
  "filter-clear": "PJ"
};
