export default {
  "row": "zQ",
  "icon": "ze",
  "cell": "zB",
  "empty": "zE",
  "no-padding": "zd",
  "cell-content": "zI",
  "right": "zt",
  "cube": "zM"
};
