export default {
  "full-height": "ccR",
  "centered": "ccQ",
  "ocr-animation": "cce",
  "separator": "ccB",
  "vat-accounting": "ccE",
  "label-title": "ccd",
  "label-select-override": "ccI",
  "amount-and-reference-wrapper": "cct",
  "amount-wrapper": "ccM",
  "payable-amount-tooltip": "ccP",
  "sticky-panel": "ccl",
  "accordion": "ccX",
  "chips-wrapper": "ccC",
  "chip": "cck"
};
