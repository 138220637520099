export default {
  "container": "cZP",
  "container--collapsed": "cZl",
  "spin": "cZX",
  "cta-tooltip": "cZC",
  "cta--collapsed": "cZk",
  "cta--expanded": "cZJ",
  "collapsed-svg-icon": "cZs",
  "expanded-svg-icon": "cZF",
  "generate-logo-cta": "cZg",
  "badge": "cZD",
  "scaleDown": "cZV",
  "fadeIn": "cZL",
  "textRiseButton": "cZT",
  "cta-description": "cZz",
  "textRiseDescription": "cZa"
};
