export default {
  "wrapper": "mq",
  "content": "mZ",
  "compact": "mR",
  "full-width": "mQ",
  "left": "me",
  "right-image": "mB",
  "right-lottie": "mE",
  "badge": "md",
  "badge-icon": "mI",
  "animation": "mt",
  "hidden": "mM"
};
