export default {
  "wrapper": "px",
  "subtitle": "pw body-2",
  "legend": "pN",
  "table": "pv",
  "footer": "po",
  "footer-codice-1": "pn",
  "footer-codice-2": "pi",
  "footer-total-a": "pA",
  "footer-total-b": "pY",
  "footer-balance": "ph",
  "errors": "pf"
};
