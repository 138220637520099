export default {
  "row": "VY body-1",
  "cell": "Vh",
  "empty": "Vf",
  "amount": "VK",
  "review": "VG",
  "cell-content": "Vr",
  "icon": "Vb",
  "name": "Vy",
  "name-text": "VU",
  "quick-actions": "Vj",
  "account-select": "VW"
};
