export default {
  "label-wrapper": "hB body-1",
  "container": "hE",
  "transaction-info": "hd",
  "secondary": "hI",
  "amount-container": "ht",
  "amount": "hM",
  "disabled": "hP",
  "checkbox": "hl",
  "disclaimer": "hX"
};
