export default {
  "mandate-new-no-mandate": "Gp",
  "left-side": "Gx",
  "left-side__wrapper": "Gw",
  "left-side__wrapper-title": "GN",
  "left-side__wrapper-details": "Gv",
  "left-side__wrapper-details-item": "Go",
  "right-side": "Gn",
  "multi-accounts": "Gi",
  "right-side__wrapper": "GA",
  "right-side__wrapper-name": "GY",
  "right-side__wrapper-address": "Gh",
  "right-side__wrapper-iban-dashed": "Gf",
  "right-side__wrapper-address-label": "GK",
  "right-side__wrapper-iban-label": "GG",
  "right-side__wrapper-bic-label": "Gr",
  "right-side__wrapper-address-dashed": "Gb",
  "right-side__wrapper-bic-dashed": "Gy",
  "dropdown-icon": "GU",
  "dropdown": "Gj",
  "multi-accounts-illustration": "GW"
};
