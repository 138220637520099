export default {
  "filter": "wc",
  "expression": "wq",
  "conditional-wrapper": "wZ mr-8",
  "dropdown-property-select": "wR",
  "is-advanced-filters-mode": "wQ",
  "dropdown-operator-select": "we",
  "conditional-text": "wB",
  "property-select": "wE",
  "operator-select": "wd",
  "error-message": "wI",
  "values-field": "wt",
  "input-placeholder": "wM",
  "filter-actions": "wP",
  "label-color": "wl",
  "button-container": "wX"
};
