export default {
  "header-cell": "TM",
  "empty": "TP",
  "right": "Tl",
  "debitor": "TX",
  "frequency": "TC",
  "date": "Tk",
  "status": "TJ",
  "amount": "Ts",
  "header-content": "TF caption-bold",
  "active": "Tg",
  "row--sidebar-open": "TD"
};
