export default {
  "wrapper": "Hf",
  "active": "HK",
  "expanded": "HG",
  "fade-in": "Hr",
  "hidden": "Hb",
  "visible": "Hy",
  "item-list": "HU",
  "item-list-slide-down": "Hj",
  "dropdown-trigger": "HW overlay",
  "settings-dropdown-slide-down": "Hu",
  "responsive": "OS",
  "is-selected": "Oc",
  "is-active": "Oq",
  "dropdown": "OZ"
};
