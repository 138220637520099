export default {
  "wrapper": "WB",
  "wrapper-revamp": "WE",
  "container": "Wd",
  "container-revamp": "WI",
  "box-wrapper": "Wt",
  "warning-label": "WM",
  "subtitle-container": "WP",
  "info-container": "Wl",
  "card": "WX",
  "disclaimer": "WC",
  "list": "Wk",
  "list-item": "WJ body-2",
  "total": "Ws"
};
