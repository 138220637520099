export default {
  "wrapper": "xX",
  "legend": "xC",
  "input": "xk",
  "table": "xJ",
  "footer": "xs",
  "footer-total-m": "xF",
  "footer-total-n": "xg",
  "footer-balance": "xD"
};
