export default {
  "dual-nav": "aa",
  "header": "aH",
  "expanded": "aO",
  "collapsed": "am",
  "responsive": "ap",
  "overlay": "ax",
  "visible": "aw",
  "footer": "aN",
  "scrolled-top": "av",
  "scrollable-content": "ao",
  "safari": "an"
};
