export default {
  "row": "tM",
  "active": "tP",
  "cell": "tl body-2",
  "empty": "tX",
  "no-padding": "tC",
  "declined": "tk",
  "not-declined": "tJ",
  "item-amount-declined": "ts",
  "cell-content": "tF",
  "item-card-name": "tg",
  "item-card-name-text": "tD",
  "item-amount": "tV body-1"
};
