export default {
  "two-column-layout": "jA",
  "points-list": "jY",
  "point-title": "jh",
  "point-content": "jf",
  "old-container": "jK",
  "columns": "jG",
  "left-column": "jr",
  "right-column": "jb",
  "video-wrapper": "jy",
  "selling-points-wrapper": "jU",
  "actions": "jj"
};
