export default {
  "sidebar": "gk",
  "scroll": "gJ",
  "header": "gs",
  "close": "gF",
  "vat": "gg",
  "labels": "gD",
  "actions": "gV",
  "buttons-container": "gL"
};
