export default {
  "container": "kI",
  "content": "kt",
  "title": "kM",
  "subtitle": "kP",
  "switch": "kl",
  "card-details": "kX",
  "asset-container": "kC",
  "asset-container-padded": "kk",
  "asset": "kJ",
  "right-container": "ks",
  "card-brand-title": "kF",
  "card-brand-badge": "kg",
  "free-subtitle": "kD",
  "benefit-container": "kV",
  "checkmark": "kL",
  "container-metal": "kT"
};
