export default {
  "container": "WG",
  "has-background": "Wr",
  "wrapper": "Wb",
  "error-wrapper": "Wy",
  "bottom-btns": "WU",
  "bottom-wrapper": "Wj",
  "tab-nav": "WW",
  "tab-nav-small": "Wu",
  "btn-clear": "uS",
  "error-illustration": "uc",
  "error-title": "uq title-1",
  "disclaimer-wrapper": "uZ",
  "disclaimer": "uR",
  "error-description": "uQ body-2",
  "table-wrapper": "ue",
  "title": "uB title-1",
  "lineup-toggle": "uE",
  "description": "ud body-2",
  "loading-wrapper": "uI",
  "card": "ut",
  "plan": "uM",
  "placeholder-header": "uP",
  "disclaimer-info": "ul",
  "spinner": "uX"
};
