export default {
  "container": "Mh",
  "container-metal": "Mf",
  "pickers": "MK",
  "picker-wrapper": "MG",
  "picker": "Mr",
  "checked": "Mb",
  "lilac": "My",
  "black": "MU",
  "mineral-gray": "Mj",
  "sand-gold": "MW",
  "graphite-black": "Mu"
};
