export default {
  "header-cell": "Vx",
  "type": "Vw",
  "request-date": "VN",
  "amount": "Vv",
  "quick-actions": "Vo",
  "empty": "Vn",
  "header-content": "Vi caption-bold",
  "active": "VA"
};
