/* import __COLOCATED_TEMPLATE__ from './feedback.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { OnboardingFeedback } from 'qonto/react/payment-links/components/flows/onboarding';

interface PaymentLinksOnboardingFeedbackSignature {
  Args: FlowStepArgs<{
    origin: string;
    draftInvoiceId?: string;
    action?: string;
  }>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinksOnboardingFeedbackComponent extends Component<PaymentLinksOnboardingFeedbackSignature> {
  OnboardingFeedback = OnboardingFeedback;

  @service declare flow: Services['flow'];
  @service declare mollie: Services['mollie'];
  @service declare router: Services['router'];
  @service declare intl: Services['intl'];

  get entryPoint() {
    if (this.args.context.draftInvoiceId) {
      return 'invoice';
    }
    if (this.args.context.origin === 'account-receivable-onboarding') {
      return 'account-receivable-onboarding';
    }
    if (this.args.context.origin === 'payment-links') {
      return 'payment-links';
    }
  }

  @action
  onCtaClick() {
    if (
      this.args.context.origin === 'payment-links' &&
      variation('feature--boolean-standalone-payment-links')
    ) {
      if (this.mollie.isEnabled) {
        this.args.transitionToFlow({
          flowName: 'payment-links-creation',
          stepId: 'products-and-services',
        });
      } else {
        window.open(
          this.intl.t('payment-links.onboarding.feedback.success.mollie-dashboard-url'),
          '_blank',
          'noopener,noreferrer'
        );
      }
      return;
    }

    this.args.context.action = 'ctaClick';
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Onboarding::Feedback': typeof PaymentLinksOnboardingFeedbackComponent;
  }
}
