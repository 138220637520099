export default {
  "wrapper": "pK",
  "legend": "pG",
  "operation-code": "pr",
  "table": "pb",
  "footer": "py",
  "footer-detraction": "pU",
  "footer-total-g": "pj",
  "footer-total-h": "pW",
  "footer-net-g-h": "pu"
};
