export default {
  "envelope": "VZ",
  "email": "VR",
  "logo": "VQ",
  "subject": "Ve",
  "message": "VB",
  "payment-details": "VE",
  "contact": "Vd",
  "detail": "VI",
  "cta": "Vt"
};
