export default {
  "journey": "UA",
  "content": "UY",
  "distance": "Uh",
  "google-link-container": "Uf",
  "link-icon": "UK",
  "continue-btn": "UG",
  "origin": "Ur",
  "input-width": "Ub"
};
