export default {
  "summary-description": "ccH",
  "detail": "ccO",
  "detail-value": "ccm",
  "account-icon": "ccp",
  "color-secondary": "ccx",
  "beneficiary-info": "ccw",
  "currency": "ccN",
  "beneficiary-name": "ccv",
  "separator": "cco",
  "instant-subtitle": "ccn",
  "instant-wrapper": "cci",
  "instant-header": "ccA",
  "instant-toggle": "ccY",
  "instant--disabled": "cch",
  "limit-exceeded-disclaimer": "ccf",
  "sticky-panel": "ccK",
  "total-interest": "ccG",
  "tooltip-icon": "ccr",
  "tooltip": "ccb",
  "payment-options": "ccy",
  "starting-on-value": "ccU",
  "standard-transfer-tooltip": "ccj"
};
