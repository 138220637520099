export default {
  "container": "NG",
  "company-details": "Nr",
  "sideview": "Nb",
  "form": "Ny",
  "french-company-details-fieldset": "NU",
  "footer": "Nj",
  "pdf-preview": "NW",
  "de-layout": "Nu",
  "de-pdf-preview": "vS",
  "german-preview": "vc"
};
