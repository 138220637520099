export default {
  "color-option": "cqj",
  "color-dot": "cqW",
  "any": "cqu",
  "red": "cZS",
  "orange": "cZc",
  "yellow": "cZq",
  "green": "cZZ",
  "blue": "cZR",
  "purple": "cZQ",
  "pink": "cZe",
  "black": "cZB",
  "grey": "cZE",
  "brown": "cZd",
  "color-name": "cZI"
};
