export default {
  "step-container": "fn",
  "content": "fi",
  "detail": "fA",
  "secondary": "fY",
  "reference": "fh",
  "fees-label": "ff",
  "fees-tooltip": "fK",
  "separator": "fG",
  "mt-32": "fr"
};
