export default {
  "wrapper": "QC",
  "core-information": "Qk",
  "iban": "QJ",
  "bic": "Qs",
  "full-line": "QF",
  "secondary-information": "Qg",
  "title": "QD",
  "rib": "QV",
  "rib-copy": "QL",
  "flex-column": "QT",
  "label": "Qz body-1",
  "value": "Qa body-2",
  "right-spaced": "QH",
  "name": "QO title-3",
  "disclaimer": "Qm"
};
