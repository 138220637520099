export default {
  "edit": "MJ",
  "upsell": "Ms",
  "options": "MF",
  "option": "Mg body-2 mb-16",
  "option-line": "MD",
  "category-tags": "MV",
  "option-icons": "ML",
  "option-icon": "MT",
  "valid": "Mz"
};
