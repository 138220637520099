export default {
  "row": "EX body-1",
  "cell": "EC",
  "counterparty": "Ek",
  "counterparty-icon": "EJ",
  "avatar": "Es",
  "icon": "EF",
  "counterparty-content": "Eg",
  "item-counterparty-name": "ED",
  "counterparty-name": "EV body-1",
  "counterparty-status": "EL",
  "member": "ET",
  "method": "Ez",
  "operation-date": "Ea",
  "amount": "EH",
  "remove-transaction": "EO",
  "menu-item": "Em",
  "remove": "Ep",
  "tooltip": "Ex",
  "empty": "Ew"
};
