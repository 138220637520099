export default {
  "sidebar": "kf",
  "sidebar-header": "kK",
  "close-button": "kG",
  "setup-mode-content": "kr",
  "edit-mode-content": "kb",
  "reminders-header": "ky",
  "reminders-title-container": "kU",
  "reminders-title": "kj",
  "reminders-subtitle": "kW",
  "reminders-list": "ku",
  "reminder": "JS",
  "reminder-due-date": "Jc",
  "upsell-button-container": "Jq"
};
