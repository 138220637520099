export default {
  "step-container": "yc",
  "subtitle": "yq",
  "step-container-content": "yZ",
  "continue-button": "yR",
  "period-select": "yQ",
  "period-dropdown": "ye",
  "readonly": "yB",
  "period-error": "yE",
  "toggle-options-container": "yd",
  "receipt-code-toggle-input": "yI"
};
