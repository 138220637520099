export default {
  "wrapper": "xS",
  "subtitle": "xc body-2",
  "legend": "xq",
  "table": "xZ",
  "footer": "xR",
  "footer-total-c": "xQ",
  "footer-total-d": "xe",
  "footer-balance": "xB"
};
