export default {
  "header-cell": "xm caption-bold",
  "sortable": "xp",
  "animated": "xx",
  "fadein-item": "xw",
  "animated-cell": "xN",
  "col1": "xv",
  "header-content": "xo caption-bold",
  "active": "xn"
};
