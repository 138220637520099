export default {
  "placeholder-row": "kN",
  "item-row": "kv",
  "active": "ko",
  "animated": "kn",
  "fadein-item": "ki",
  "cell": "kA",
  "cell-content": "kY",
  "disabled": "kh"
};
