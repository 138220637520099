export default {
  "row": "Tw body-1",
  "active": "TN",
  "cell": "Tv body-2",
  "empty": "To",
  "no-padding": "Tn",
  "cell-content": "Ti",
  "debitor": "TA",
  "avatar": "TY",
  "debitor-details": "Th",
  "debitor-name": "Tf",
  "reference": "TK",
  "amount": "TG",
  "row--sidebar-open": "Tr",
  "status": "Tb"
};
