export default {
  "step-container": "Kd",
  "content": "KI",
  "section": "Kt",
  "section-content": "KM",
  "section-content-text": "KP",
  "section-illustration": "Kl",
  "close-form-cta": "KX",
  "bank-account-select": "KC"
};
