export default {
  "header-cell": "CJ",
  "border-top": "Cs",
  "header-content": "CF caption-bold",
  "active": "Cg",
  "empty": "CD",
  "card": "CV",
  "card-holder": "CL",
  "limit": "CT",
  "align-right": "Cz",
  "status": "Ca",
  "hidden": "CH",
  "no-padding": "CO"
};
