export default {
  "invite-review": "bT",
  "invite-review-info": "bz",
  "invite-review-info--invitee": "ba",
  "disclaimer-button": "bH",
  "info-block": "bO",
  "ev": "bm",
  "tooltip": "bp",
  "description": "bx"
};
