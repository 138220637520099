export default {
  "mandates": "mg",
  "mandates-list": "mD",
  "left-section": "mV",
  "header-search": "mL",
  "header": "mT",
  "header-content": "mz",
  "search": "ma",
  "search-bar": "mH",
  "body": "mO",
  "list-title": "mm caption-bold",
  "details": "mp",
  "avatar": "mx mr-16",
  "header-beneficiary": "mw",
  "header-beneficiary-activity": "mN",
  "header-beneficiary-details": "mv",
  "header-beneficiary-details-recurring": "mo",
  "infos": "mn",
  "infos-title": "mi",
  "infos-empty": "mA",
  "infos-list": "mY",
  "infos-list-item": "mh",
  "infos-list-item-left": "mf",
  "infos-list-item-right": "mK",
  "infos-list-item-attachment": "mG"
};
