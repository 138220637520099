export default {
  "row": "JP",
  "active": "Jl",
  "cell": "JX",
  "name-cell": "JC",
  "email-cell": "Jk",
  "actions-cell": "JJ",
  "name-container": "Js",
  "quick-actions-wrapper": "JF",
  "empty": "Jg",
  "name-icon-container": "JD",
  "icon": "JV",
  "tooltip-container": "JL",
  "missing-details-container": "JT",
  "missing-details-label": "Jz body-2",
  "warning-icon": "Ja JV"
};
