export default {
  "container": "oT",
  "customize-field": "oz",
  "content": "oa",
  "sideview": "oH",
  "form": "oO",
  "footer": "om",
  "layout": "op",
  "pdf-preview": "ox",
  "german-preview": "ow"
};
