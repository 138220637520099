export default {
  "row": "zP body-1",
  "active": "zl",
  "cell": "zX body-2",
  "empty": "zC",
  "no-padding": "zk",
  "cell-content": "zJ",
  "item-disabled": "zs",
  "item-amount": "zF body-1",
  "centered-cell": "zg",
  "debitor": "zD",
  "item-counterparty-name": "zV",
  "item-counterparty-reference": "zL",
  "item-counterparty-name-status": "zT",
  "item-counterparty-name-text": "zz",
  "amount-on-hold": "za",
  "icon-on-hold": "zH"
};
