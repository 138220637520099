export default {
  "dates-filter": "wC",
  "dates-wrapper": "wk",
  "date-interval": "wJ",
  "date-field-wrapper": "ws",
  "dates-conditional": "wF",
  "placeholder": "wg",
  "error": "wD",
  "error-message": "wV body-2"
};
