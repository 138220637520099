export default {
  "row": "Cm",
  "active": "Cp",
  "animated": "Cx",
  "fadein-item": "Cw",
  "cell": "CN body-2",
  "cell-content": "Cv",
  "no-padding": "Co",
  "hidden": "Cn",
  "disabled": "Ci",
  "not-disabled": "CA",
  "card-icon": "CY",
  "card-infos": "Ch",
  "card-digits": "Cf",
  "card-digits-warning": "CK",
  "status-puce": "CG",
  "live": "Cr",
  "waiting-funds": "Cb",
  "ghost": "Cy",
  "payment-limit-bar": "CU",
  "align-right": "Cj"
};
