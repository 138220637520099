export default {
  "card": "dy",
  "avatar": "dU",
  "content": "dj",
  "content-top": "dW",
  "fullname": "du",
  "representative": "IS",
  "icon": "Ic",
  "type": "Iq",
  "menu-container": "IZ",
  "dropdown-menu": "IR",
  "menu-item": "IQ body-2",
  "delete": "Ie"
};
