export default {
  "header-cell": "Ty",
  "empty": "TU",
  "right": "Tj",
  "debitor": "TW",
  "date": "Tu",
  "status": "zS",
  "amount": "zc",
  "header-amount": "zq",
  "header-content": "zZ caption-bold",
  "active": "zR"
};
