export default {
  "animated": "iG",
  "container": "ir",
  "header": "ib",
  "footer": "iy",
  "skip-cta": "iU",
  "pricing-caption": "ij",
  "asset-x": "iW",
  "asset-plus": "iu",
  "asset-one": "AS",
  "show": "Ac",
  "selector": "Aq",
  "selectorFadeIn": "AZ",
  "fadeIn": "AR",
  "onePlusAssetFadeIn": "AQ",
  "assetMoveUp": "Ae",
  "xAssetFadein": "AB",
  "feature": "AE",
  "highlighted": "Ad"
};
