export default {
  "cockpit-list": "Dy",
  "cockpit-item": "DU",
  "cockpit-item-tile": "Dj",
  "guarding-title": "DW",
  "guarding-title-icon": "Du",
  "subtitle-link": "VS",
  "subtitle-wrapper": "Vc",
  "subtitle-tooltip": "Vq"
};
