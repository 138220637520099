export default {
  "voucher-wrapper": "cSt",
  "left-side": "cSM",
  "left-side__wrapper": "cSP",
  "voucher-illustration": "cSl",
  "left-side__wrapper-title": "cSX",
  "voucher-list": "cSC",
  "left-side__wrapper-details": "cSk",
  "right-side": "cSJ",
  "multi-accounts": "cSs",
  "right-side__wrapper": "cSF"
};
