export default {
  "checks": "hL",
  "content": "hT",
  "form": "hz",
  "title": "ha",
  "description": "hH",
  "wrapper": "hO",
  "aside": "hm",
  "amount-field": "hp"
};
