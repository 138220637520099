/* import __COLOCATED_TEMPLATE__ from './flow-in-flow.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, type TaskInstance } from 'ember-concurrency';

export interface FlowStepArgs<T extends {} = {}> {
  context: T;
  stepList: {
    id: string;
    nextStepId: string | null;
    componentName: string;
    flowName: string;
  }[];
  abort: () => TaskInstance<void>;
  backToStep: (stepId: string, flowName?: string) => void;
  completeFlow: () => void;
  pushFlow: (flowName: string, stepId: string) => void;
  pushForwardFlow: (flowName: string, stepId: string) => void;
  restartFlow: () => TaskInstance<void>;
  transitionToNext: () => void;
  transitionToPrevious: () => void;
  transitionToFlow: ({
    flowName,
    stepId,
    queryParams,
  }: {
    flowName: string;
    stepId: string;
    queryParams?: object;
  }) => TaskInstance<void>;
}

interface FlowInFlowSignature {
  Args: {};
  Element: HTMLElement;
}

export default class FlowInFlowComponent extends Component<FlowInFlowSignature> {
  @service declare flow: Services['flow'];

  get flowName() {
    return this.flow.currentStep.value.flowName;
  }

  get stepId() {
    return this.flow.currentStep.value.id;
  }

  get stepComponentName() {
    let { componentName, component } = this.flow.currentStep.value;

    if (component) {
      return component;
    }

    return `flows/${componentName}`;
  }

  handleAbortTask = dropTask(async () => {
    if (this.flow.isPrimaryFlowLastStep) {
      this.flow.complete();
    } else {
      await this.flow.abortTask.unlinked().perform();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FlowInFlow: typeof FlowInFlowComponent;
  }
}
