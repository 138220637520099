export default {
  "step-container": "yD",
  "flex-table": "yV",
  "flex-row": "yL",
  "flex-cell": "yT",
  "with-border": "yz",
  "with-border-top": "ya",
  "secondary": "yH",
  "flex-info": "yO",
  "amount": "ym",
  "legal-name": "yp"
};
