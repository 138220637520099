export default {
  "sidebar-header": "Lr",
  "sidebar-header__top": "Lb",
  "sidebar-header__close-button": "Ly",
  "close-button": "LU",
  "sidebar-header__body": "Lj",
  "sidebar-header__content": "LW",
  "sidebar-header__date": "Lu",
  "infobox": "TS",
  "infobox--link": "Tc",
  "ghost": "Tq",
  "fail": "TZ",
  "success": "TR"
};
