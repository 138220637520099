export default {
  "form": "wp",
  "fieldset": "wx",
  "form-body": "ww",
  "conditional-select": "wN",
  "conditional-text": "wv",
  "group": "wo",
  "add-filter-button": "wn btn btn--tertiary",
  "footer": "wi",
  "btn-icon": "wA",
  "add-icon": "wY",
  "cancel-button": "wh btn",
  "form-header": "wf",
  "align-start": "wK",
  "align-right": "wG",
  "button-wrapper": "wr",
  "wrapper": "wb",
  "label": "wy body-2",
  "input-wrapper": "wU",
  "input": "wj input-field body-2",
  "error": "wW",
  "error-message": "wu"
};
