export default {
  "row": "DQ",
  "active": "De",
  "cell": "DB",
  "empty-cell": "DE",
  "counterparty": "Dd",
  "counterparty-info": "DI",
  "name": "Dt body-1",
  "qonto": "DM",
  "currency": "DP",
  "account-number": "Dl caption",
  "custom-labels": "DX",
  "label": "DC"
};
