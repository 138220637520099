export default {
  "card": "dz",
  "row": "da",
  "icon": "dH",
  "unrevokable": "dO",
  "active": "dm",
  "kyc-pending": "dp",
  "shareholder-info": "dx",
  "name": "dw",
  "iban": "dN",
  "tooltip": "dv",
  "shareholder-capital": "do",
  "shareholder-capital-amount": "dn",
  "deposit-capital": "di",
  "valid": "dA",
  "pending": "dY",
  "promised-capital-valid": "dh",
  "promised-capital": "df"
};
