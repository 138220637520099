export default {
  "rule-card": "FS",
  "rule-content": "Fc",
  "rule-options": "Fq",
  "visible": "FZ",
  "dropdown-btn": "FR",
  "delete-btn": "FQ",
  "btn--tertiary": "Fe",
  "delete-rule-btn": "FB",
  "transaction-estimate": "FE"
};
