export default {
  "container": "PV",
  "content": "PL",
  "table": "PT",
  "table-body": "Pz",
  "title": "Pa",
  "title-with-badge": "PH",
  "metal-card-title": "PO",
  "subtitle": "Pm",
  "card-image": "Pp",
  "button-container": "Px"
};
