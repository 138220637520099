export default {
  "container": "AM",
  "container-metal": "AP",
  "content": "Al",
  "button": "AX",
  "embossed-selector": "AC",
  "carousel-container": "Ak",
  "carousel": "AJ",
  "asset": "As",
  "poster": "AF",
  "video-embossed": "Ag",
  "video": "AD",
  "active": "AV",
  "embossed": "AL",
  "safari": "AT",
  "poster-open": "Az"
};
