export default {
  "modal": "FU",
  "features": "Fj",
  "close-button": "FW",
  "header": "Fu title-2",
  "header-text": "gS body-2",
  "feature": "gc",
  "subheader": "gq title-4",
  "text": "gZ body-2",
  "cta": "gR",
  "container-img": "gQ",
  "img": "ge",
  "link": "gB"
};
