export default {
  "hero-gradient": "lZ",
  "hero-wrapper": "lR",
  "container-text": "lQ",
  "title-with-badge": "le",
  "recycled-badge": "lB",
  "hero-label": "lE",
  "hero-card": "ld",
  "svg-gradient": "lI",
  "design": "lt",
  "design-image-container": "lM",
  "design-image-overlay": "lP",
  "design-item": "ll",
  "hero-title": "lX",
  "hero-subtitle": "lC",
  "metal-illustration": "lk",
  "cards": "lJ",
  "metal-illustration-old": "ls",
  "rotating": "lF",
  "verticaling": "lg",
  "card": "lD",
  "circle": "lV"
};
