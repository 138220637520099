export default {
  "tile-layout": "QG",
  "focus": "Qr",
  "no-shadow": "Qb",
  "tile-layout--start-verification": "Qy",
  "header": "QU",
  "headings": "Qj",
  "management": "QW",
  "logo": "Qu",
  "title": "eS title-4",
  "name": "ec title-4",
  "loading": "eq",
  "menu-item": "eZ body-2",
  "close-account": "eR",
  "content": "eQ"
};
