/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/connections/sidebar/header';

interface ConnectionsSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConnectionsSidebarComponent extends Component<ConnectionsSidebarSignature> {
  header = Header;

  @service declare abilities: Services['abilities'];
  get scopes() {
    // @ts-expect-error
    return this.args.connection?.grantedScopes.split(' ') || [];
  }

  get showCta() {
    return this.abilities.can('revoke oauth');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Apps::Sidebar': typeof ConnectionsSidebarComponent;
  }
}
