export default {
  "wrapper": "zY",
  "badge-trial": "zh",
  "header": "zf",
  "list": "zK body-2",
  "main": "zG",
  "card": "zr",
  "container": "zb",
  "header-button": "zy",
  "error-text": "zU",
  "mt-16": "zj"
};
