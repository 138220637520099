export default {
  "container": "oR",
  "summary": "oQ",
  "sideview": "oe",
  "steps-container": "oB",
  "invoice-upload-container": "oE",
  "pdf-preview": "od",
  "uploader": "oI",
  "template-text": "ot",
  "file-dropzone": "oM",
  "overlay": "oP",
  "footer": "ol",
  "upload-prompt": "oX",
  "step-selector": "oC",
  "disclaimer": "ok"
};
