export default {
  "content": "ee",
  "balance": "eB",
  "balance-failed": "eE",
  "bank-infos": "ed",
  "bank-name": "eI",
  "bank-identifier": "et",
  "bank-iban": "eM",
  "footer-container": "eP",
  "links": "el",
  "links--start-verification": "eX",
  "link": "eC",
  "start-verification-link": "ek",
  "last-sync": "eJ"
};
