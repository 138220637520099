export default {
  "upsell-cta": "bi",
  "discover-badge": "bA",
  "role-selection-item": "bY",
  "unavailable": "bh",
  "role-headline": "bf",
  "illustration": "bK",
  "role-description": "bG",
  "description-item": "br",
  "description-icon": "bb",
  "description-text": "by",
  "current-role": "bU",
  "icon-outlined": "bj"
};
