export default {
  "card": "Iz",
  "avatar": "Ia",
  "owner": "IH",
  "content": "IO",
  "identity": "Im",
  "fullname": "Ip",
  "type": "Ix",
  "menu-container": "Iw",
  "dropdown-menu": "IN",
  "menu-item": "Iv body-2",
  "delete": "Io"
};
