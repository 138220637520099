export default {
  "wrapper": "af",
  "header": "aK",
  "no-separator": "aG",
  "tabs": "ar",
  "tab": "ab body-1 overlay",
  "selected": "ay",
  "organization-list": "aU",
  "content": "aj",
  "empty-state": "aW",
  "illustration": "au",
  "body": "HS body-1",
  "cta": "Hc btn btn--tertiary"
};
