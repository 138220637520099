export default {
  "budget-creation-form": "Bx",
  "period-label": "Bw",
  "periods": "BN",
  "yearly": "Bv",
  "yearly-toggle": "Bo",
  "toggle-tooltip": "Bn",
  "tooltip": "Bi",
  "year": "BA",
  "total-budget": "BY",
  "total-budget-value": "Bh",
  "divider": "Bf"
};
