export default {
  "tooltip": "HP",
  "wrapper": "Hl",
  "collapsed": "HX",
  "label": "HC body-1",
  "selected": "Hk",
  "icon-wrapper": "HJ",
  "icon": "Hs",
  "locked-icon": "HF",
  "notification-count": "Hg",
  "fade-in": "HD",
  "responsive": "HV"
};
