export default {
  "container": "Ax",
  "centered": "Aw",
  "content": "AN",
  "spinner": "Av",
  "holder-selector-wrapper": "Ao mb-16",
  "holder-selector-error": "An",
  "info-text": "Ai",
  "error-text": "AA",
  "lottie-illustration": "AY",
  "info-text-1": "Ah",
  "info-text-2": "Af"
};
