export default {
  "row": "TV",
  "icon": "TL",
  "cell": "TT",
  "empty": "Tz",
  "no-padding": "Ta",
  "cell-content": "TH",
  "right": "TO",
  "cube": "Tm",
  "row--sidebar-open": "Tp",
  "status": "Tx"
};
