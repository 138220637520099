/* import __COLOCATED_TEMPLATE__ from './payment-details.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import {
  Accordion,
  AmountField,
  Checkbox,
  ChoiceChip,
  DatePicker,
  Disclaimer,
  TextField,
} from '@repo/design-system-kit';
import { PaymentDetailSkeleton } from '@repo/domain-kit/transfers';
import dayjs from 'dayjs';
import { all, dropTask, race, rawTimeout, task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import {
  PAY_LATER_FLOW_ORIGIN,
  PAY_LATER_VALIDATION_ERROR_TYPES,
  PAY_LATER_VALIDATION_ERRORS,
} from 'qonto/constants/financing';
// @ts-expect-error
import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import {
  PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS,
  PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import type SupplierModel from 'qonto/models/supplier';
import type TransferModel from 'qonto/models/transfer';
import { PaymentDetailsCard } from 'qonto/react/components/transfers/sepa/review-payment-details/payment-details-card';
import type { Attachment } from 'qonto/react/models/transaction';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { calculateVatAmount } from 'qonto/utils/transfers';
// @ts-expect-error
import createPayLaterTransferValidations from 'qonto/validations/pay-later-transfer';

const PAY_LATER_BLOCKING_ERRORS = [
  PAY_LATER_VALIDATION_ERRORS.PREVENT_FF,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_LESS_THAN_THRESHOLD,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_MORE_THAN_AVAILABLE_CREDIT,
  PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE,
];

interface Amount {
  value: string;
  currency: string;
}

interface FlowsTransfersSepaNewPaymentDetailsSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
    context: {
      sepaTransfer: TransferModel;
      invoice: {
        id: string;
        dueDate: string;
        supplierSnapshot: SupplierModel;
        relatedInvoices: [];
        totalAmountCreditNotes: Amount;
        payableAmount: Amount;
        totalAmount: Amount;
        frenchEInvoicing: boolean;
        isGermanEInvoice: boolean;
        isItalianEInvoice: boolean;
        attachment: Attachment;
        isEinvoice: boolean;
        analyzedAt: string;
        createdAt: string;
        supplierId: string;
        supplierName: string;
      };
      origin: string;
      validationError: string[] | { type: string; errors: any } | null;
      didValidateFinancing: boolean;
      payLaterErrors: string[] | { type: string; errors: any } | null;
      isPayLaterEligible: boolean;
      didInstallmentsFail: boolean;
      isInstantFallback: boolean;
      isDedicatedFlow: boolean;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentDetailsComponent extends Component<FlowsTransfersSepaNewPaymentDetailsSignature> {
  paymentDetailsCard = PaymentDetailsCard;
  datePicker = DatePicker;
  choiceChip = ChoiceChip;
  checkbox = Checkbox;
  textField = TextField;
  amountField = AmountField;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  paymentDetailSkeleton = PaymentDetailSkeleton;
  accordionSingle = Accordion.Single;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare financing: Services['financing'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare supplierInvoicesManager: Services['supplierInvoicesManager'];
  @service declare errors: Services['errors'];

  @tracked today = dayjs().format(DATE_PICKER_FIELD_FORMAT);
  @tracked isEditing = false;
  @tracked isAmountEdited = false;
  @tracked isValidationEnabled = false;
  @tracked lastTransfer: TransferModel | undefined;
  @tracked currentModalType: 'new' | 'edit' | null = null;
  @tracked areSuppliersInitialized = false;

  supplierEditModal = null;
  supplier: SupplierModel | null = null;

  invoiceAnalysisResult = {
    amount_read: false,
    invoice_number_read: false,
  };

  @cached
  get payLaterDataModel() {
    if (this.args.context.isDedicatedFlow) {
      return this.#createPayLaterDataModel();
    }
  }

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.fetchSuppliersTask.perform().catch(ignoreCancelation);
    this.initiateTransferFromInvoiceTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
        this.toastFlashMessages.toastError(
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      });
  }

  get shouldShowOcrAnimation() {
    return this.waitForOcrScanTask.isRunning;
  }

  get supplierIbanDisclaimer() {
    let { name, iban } = this.args.context.invoice.supplierSnapshot;

    if (!name || iban) return false;

    return this.isValidationEnabled ? 'error' : 'warning';
  }

  get showSupplierMissingError() {
    let { name } = this.args.context.invoice.supplierSnapshot;

    return !name && this.isValidationEnabled;
  }

  get isLoading() {
    return !this.areSuppliersInitialized && this.fetchSuppliersTask.isRunning;
  }

  get originForErrorTrackingEvent() {
    let { origin } = this.args.context;

    switch (origin) {
      case TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE:
        return PAY_LATER_FLOW_ORIGIN.TRANSFER_FLOW;
      case TRANSFER_FLOW_ORIGIN.PAY_LATER_APP_SUCCESS:
        return PAY_LATER_FLOW_ORIGIN.SUCCESS_SCREEN;
      case TRANSFER_FLOW_ORIGIN.EMAIL_DEEPLINK:
        return PAY_LATER_FLOW_ORIGIN.DEEPLINK;
      case TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT:
        return PAY_LATER_FLOW_ORIGIN.COCKPIT;
      case TRANSFER_FLOW_ORIGIN.OVERVIEW:
        return PAY_LATER_FLOW_ORIGIN.GET_STARTED_CARD;
      case PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY:
        return PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY;
      case TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES:
      case PAY_LATER_FLOW_ORIGIN.SUPPLIER_INVOICES:
        return PAY_LATER_FLOW_ORIGIN.SUPPLIER_INVOICES;
    }
  }

  get scheduledDate() {
    return this.args.context.sepaTransfer.scheduledDate
      ? parseDate(this.args.context.sepaTransfer.scheduledDate)
      : null;
  }

  get minDate() {
    let minDate = dayjs().startOf('day').format(DATE_PICKER_FIELD_FORMAT).toString();
    return parseDate(minDate);
  }

  get showDateChoiceChips() {
    let invoiceDueDate = this.args.context.invoice.dueDate;
    if (!invoiceDueDate) return false;

    let invoiceDueDateToday = dayjs(invoiceDueDate).format('YYYY-MM-DD');

    return dayjs(invoiceDueDateToday).diff(dayjs(this.today)) >= 0;
  }

  get isPaymentDateToday() {
    return this.today === this.args.context.sepaTransfer.scheduledDate;
  }

  get isPaymentDateInvoiceDate() {
    return this.args.context.sepaTransfer.scheduledDate === this.args.context.invoice.dueDate;
  }

  get isBookkeepingExpanded() {
    let hasCustomLabelsCreated = this.args.context.sepaTransfer.organization
      .get('labelLists')
      .some((labelList: { labels: [] }) => labelList.labels.length);

    return hasCustomLabelsCreated || this.lastTransfer?.vatRate;
  }

  get hasCreditNoteLinked() {
    return this.args.context.invoice.relatedInvoices?.length;
  }

  get showPayableAmountDisclaimer() {
    return !this.isAmountEdited && this.hasCreditNoteLinked;
  }

  get totalCreditNotesAmount() {
    let { totalAmountCreditNotes } = this.args.context.invoice;

    return totalAmountCreditNotes
      ? // @ts-expect-error
        this.intl.formatMoney(totalAmountCreditNotes?.value, {
          currency: totalAmountCreditNotes?.currency,
          signus: '-',
        })
      : 0;
  }

  get creditNotesCount() {
    return this.args.context.invoice.relatedInvoices?.length || 0;
  }

  get payableAmount() {
    let { payableAmount } = this.args.context?.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get isEInvoice() {
    let {
      frenchEInvoicing = false,
      isGermanEInvoice = false,
      isItalianEInvoice = false,
    } = this.args.context.invoice;

    return frenchEInvoicing || isGermanEInvoice || isItalianEInvoice;
  }

  get shouldShowExpanded() {
    let origin = this.args.context.origin;
    return origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE || this.args.context.isDedicatedFlow;
  }

  get payLaterTransferFlowOrigin() {
    if (this.args.context.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      return TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES;
    }

    return TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE;
  }

  get invoiceLifeTime() {
    return Math.max(dayjs().diff(this.args.context.invoice.createdAt), 0) || 0;
  }

  get shouldTrackOcrResult() {
    return this.waitForOcrScanTask.performCount === 1;
  }

  get amountPercentage() {
    if (!this.args.context?.sepaTransfer?.amount || !this.args.context?.invoice?.payableAmount) {
      return 0;
    }

    const percentage =
      (this.args.context.sepaTransfer.amount /
        parseFloat(this.args.context.invoice.payableAmount.value)) *
      100;
    return Math.round(percentage);
  }

  get amountFieldDescription() {
    return this.payableAmount && this.amountPercentage > 0
      ? this.intl.t('transfers.pay-by-invoice.payment-details.amount.helper', {
          paymentPortion: this.amountPercentage,
        })
      : '';
  }

  @action
  handleLabelSelect(labelList: [], label: {}) {
    let { sepaTransfer } = this.args.context;
    sepaTransfer.labels = getLabelsToUpdate(sepaTransfer.labels, labelList, label);
    return sepaTransfer;
  }

  @action
  setScheduledDate(date: Date) {
    this.args.context.sepaTransfer.scheduledDate = date?.toString();
  }

  @action
  onDateChipClick(chip: string) {
    if (chip === 'today') {
      this.args.context.sepaTransfer.scheduledDate = this.today;
    } else if (chip === 'invoice-date') {
      this.args.context.sepaTransfer.scheduledDate = this.args.context.invoice.dueDate;
    }
  }

  @action
  onEditClick() {
    let { invoice } = this.args.context;
    this.isEditing = true;
    this.segment.track('transfer-sepa_edit_clicked', {
      flow: 'byinvoice',
      origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      is_einvoice: invoice.isEinvoice,
    });
  }

  @action
  onNotifyByEmailChange(value: boolean) {
    if (value) {
      this.args.context.sepaTransfer.email = this.args.context.invoice.supplierSnapshot.email;
    } else {
      this.args.context.sepaTransfer.email = '';
    }

    this.args.context.sepaTransfer.notifyByEmail = value;
  }

  // Disabled rule because using .then in `handleSaveVatAmount`
  // @ts-expect-error
  // eslint-disable-next-line require-await
  async handleSelectVat(model, value) {
    model.setProperties(value);
    return model;
  }

  @action
  onAmountChange(amount: number) {
    this.isAmountEdited = true;
    this.args.context.sepaTransfer.amount = amount;
    this._updateVatAmount();
  }

  @action
  onReferenceChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.args.context.sepaTransfer.reference = event.currentTarget?.value;
  }

  @action
  updateSupplier(prop: string, value: string) {
    // @ts-expect-error
    this.args.context.invoice[prop] = value;
  }

  @action
  onCloseRedirect() {
    // @ts-expect-error
    this.supplierEditModal.close();
  }

  _updateVatAmount() {
    let { sepaTransfer } = this.args.context;
    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      sepaTransfer.vatAmount = Number(calculateVatAmount(amount, vatRate));
    }
  }

  fetchSuppliersTask = dropTask(async (search = '') => {
    let suppliers = await this.store.query('supplier', {
      search,
      filter: { status: SUPPLIER_STATUSES.UNARCHIVED },
    });
    this.areSuppliersInitialized = true;
    return suppliers;
  });

  onClickContinueTask = dropTask(async confirmTransferTask => {
    let { context, transitionToNext } = this.args;
    let { invoice: supplierInvoice, sepaTransfer } = context;
    let { supplierSnapshot: supplier } = supplierInvoice;

    if (supplier.name && supplier.iban) {
      let beneficiary = this.beneficiariesManager.createSepaBeneficiary(
        this.organizationManager.organization,
        {
          iban: supplier.iban,
          name: supplier.name,
          vatRate: supplier.defaultVatRate,
        }
      );

      try {
        await beneficiary.save();
        this.args.context.sepaTransfer.name = beneficiary.name;
        this.args.context.sepaTransfer.iban = beneficiary.iban;
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      } finally {
        sepaTransfer.set('beneficiary', beneficiary);
      }
    }

    if (!sepaTransfer.usePayByInvoiceValidations) {
      sepaTransfer.usePayByInvoiceValidations = true;
    }
    await sepaTransfer.validate();

    let { amount, bankAccount, reference, scheduledDate, email } = sepaTransfer.validations.attrs;

    if (
      bankAccount.isInvalid ||
      reference.isInvalid ||
      scheduledDate.isInvalid ||
      (sepaTransfer.notifyByEmail && email.isInvalid) ||
      !supplier.name ||
      !supplier.iban
    ) {
      this.isEditing = true;
      this.isValidationEnabled = true;
      this._scrollToErrorField();
      return;
    }

    if (context.isDedicatedFlow) {
      let hasBeneficiary = sepaTransfer.get('beneficiary').content;

      let isFormValid = await this.validateInlineForm();

      if (!isFormValid || !hasBeneficiary) {
        this._scrollToErrorField();
        return;
      }
      try {
        context.didValidateFinancing = false;
        await this.handleValidationTask.perform();
        context.didValidateFinancing = true;

        this.segment.track('pay-later-dedicated-flow_errors_detected', {
          origin: this.originForErrorTrackingEvent,
          error: context.validationError
            ? Array.isArray(context.validationError)
              ? context.validationError
              : context.validationError.errors
            : ['no_error'],
        });

        context.isInstantFallback = false;
      } catch (error) {
        // @ts-expect-error
        let findAttachmentIdError = error.errors?.find(
          ({ code }: { code: string }) => code === 'attachment_id_not_found'
        );
        // @ts-expect-error
        if (error.status === 404 && findAttachmentIdError) {
          context.validationError = {
            type: PAY_LATER_VALIDATION_ERROR_TYPES.ATTACHMENT_ID,
            errors: [findAttachmentIdError.code],
          };
        } else {
          return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    }

    if (context.isPayLaterEligible && !context.isDedicatedFlow) {
      try {
        context.didValidateFinancing = false;
        await this.validateFinancingTask.perform();
        context.didValidateFinancing = true;
      } catch (error) {
        // @ts-expect-error
        if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
          this.sentry.captureException(error);
        }
      }
    }

    this.isValidationEnabled = true;

    if (amount.isInvalid) {
      this.isEditing = true;
      this.handleLowBalanceTask.perform().catch(ignoreCancelation);

      this._scrollToErrorField();
      return;
    }

    let confirmationResult = await confirmTransferTask.perform(sepaTransfer);

    if (confirmationResult) {
      await this.handlePayLaterInstallments.perform();

      if (context.didInstallmentsFail && context.isDedicatedFlow) {
        // In Pay later transfer flow, user can only continue if we have the installments data
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        return;
      }
      this._trackContinueCTA();

      transitionToNext();
    }
  });

  async validateInlineForm() {
    let { validations } = await this.payLaterDataModel.validate();

    this.isValidationEnabled = true;

    return validations.isValid;
  }

  handleLowBalanceTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer } = context;

    let isLowBalanceScenario =
      Number(sepaTransfer.amount) > Number(sepaTransfer.bankAccount.authorizedBalance);

    if (!context.isPayLaterEligible || !isLowBalanceScenario) return;

    if (!context.didValidateFinancing) {
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }

    // @ts-expect-error
    if (!PAY_LATER_BLOCKING_ERRORS.some(error => context.payLaterErrors?.includes(error))) {
      await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
        confirmTask: this.redirectToPayLaterFlowTask,
      });
    }
  });

  redirectToPayLaterFlowTask = dropTask(async close => {
    let { context } = this.args;

    await close();

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'payment-details',
      queryParams: {
        origin: this.payLaterTransferFlowOrigin,
        invoiceId: context.invoice.id,
        transferId: context.sepaTransfer.idempotencyKey,
      },
    });
  });

  validateFinancingTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer, invoice } = context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    let attachmentId = invoice.attachment.id;
    let beneficiary = sepaTransfer.belongsTo('beneficiary').value();
    let iban = beneficiary.iban;
    let beneficiaryName = beneficiary.name;
    let selectedAccountIban = sepaTransfer.bankAccount.iban;
    let scheduledDate = sepaTransfer.scheduledDate;
    let operationType = sepaTransfer.operationType;

    context.payLaterErrors = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      selectedAccountIban,
      scheduledDate,
      operationType,
    });

    this.segment.track('pay-later_transfer-flow_errors_detected', {
      error: context.payLaterErrors || ['no_error'],
    });
  });

  handleValidationTask = task(async () => {
    let { sepaTransfer, invoice } = this.args.context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    // @ts-expect-error
    let attachmentId = invoice.attachment.get('id');
    let iban = sepaTransfer.beneficiary.get('iban');
    let beneficiaryName = sepaTransfer.beneficiary.get('name');

    this.args.context.validationError = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      groupErrors: true,
    });
  });

  handlePayLaterInstallments = dropTask(async () => {
    let { context } = this.args;

    let { invoice, payLaterErrors, isPayLaterEligible, didValidateFinancing, sepaTransfer } =
      context;
    let { amount, amountCurrency } = sepaTransfer;

    context.didInstallmentsFail = false;

    if (!invoice || !didValidateFinancing || !isPayLaterEligible || payLaterErrors) {
      return;
    }

    try {
      // @ts-expect-error
      context.payLaterInstallments = await this.financing.getPayLaterTransferInstallments({
        value: amount,
        currency: amountCurrency,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      context.didInstallmentsFail = true;
    }
  });

  waitForOcrScanTask = dropTask(async () => {
    let { invoice, origin } = this.args.context;

    this.segment.track('transfer-sepa_OCR_transition_displayed', {
      ...(origin && { origin }),
    });

    let waitForOcrAnimation = rawTimeout(PAY_BY_INVOICE_ANIMATION_IN_DURATION_MS);
    let waitForScanCompletionOrDelay = this.waitForScanCompletionOrDelayTask.perform();

    let [eventedResponse] = await all([waitForScanCompletionOrDelay, waitForOcrAnimation]);

    if (eventedResponse?.event === SUPPLIER_INVOICE_EVENTS.ANALYZED) {
      let { payload } = eventedResponse;
      // @ts-expect-error
      let id = this.supplierInvoicesManager.getSupplierInvoiceIdFromAnalyzedEvent(payload);

      if (id === invoice.id) {
        await this.prefillTransferTask.perform(invoice);
      }
    } else {
      // If we timed out waiting for the websocket, we try to refetch and prefill last time
      await this.prefillTransferTask.perform(invoice);
    }
  });

  waitForScanCompletionOrDelayTask = task(async () => {
    // @ts-expect-error
    let isInvoiceScanned = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.ANALYZED
    );
    let delay = rawTimeout(PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN);
    return await race([isInvoiceScanned, delay]);
  });

  prefillTransferTask = dropTask(async invoice => {
    await invoice.reload();

    let { sepaTransfer } = this.args.context;
    let { invoiceNumber, amountToPay } = invoice;

    this.invoiceAnalysisResult.amount_read = Boolean(amountToPay?.value);
    this.invoiceAnalysisResult.invoice_number_read = Boolean(invoiceNumber);

    // Paylater allows users to enter a certain amount to pay. In cases where there is already an invoice, our getter, `amountToPay`, in the
    // model returns the amount of the invoice and we use it as the amount in the sepaTransfer object. This amount from the invoice
    // is not aware that another amount has been populated to be used as the transferAmount.
    sepaTransfer.setProperties({
      amount: sepaTransfer?.amount ? sepaTransfer.amount : amountToPay?.value,
      reference:
        sepaTransfer.reference ||
        (invoiceNumber
          ? this.intl.t('transfers.pay-by-invoice.payment-details.reference.value', {
              invoiceNumber,
            })
          : ''),
    });

    let hasOCRSucceded = [
      this.invoiceAnalysisResult.amount_read,
      this.invoiceAnalysisResult.invoice_number_read,
    ].some(Boolean);
    if (!hasOCRSucceded) {
      this.toastFlashMessages.toastError(this.intl.t('errors.invoice_ocr_failed'));
    }
  });

  initiateTransferFromInvoiceTask = task(async () => {
    let tasks = [];

    let { invoice } = this.args.context;
    let { analyzedAt } = invoice;

    if (!analyzedAt && this.invoiceLifeTime <= PAY_BY_INVOICE_WAIT_FOR_OCR_SCAN) {
      tasks.push(this.waitForOcrScanTask.perform());
    } else {
      tasks.push(this.prefillTransferTask.perform(invoice));
      tasks.push(this.prefillInvoiceByLastTransferTask.perform());
    }

    await all(tasks);

    if (this.shouldTrackOcrResult) {
      let { origin } = this.args.context;
      this.segment.track('transfer-sepa_transfer_details_displayed', {
        flow: 'byinvoice',
        ...(origin && { origin }),
        ...this.invoiceAnalysisResult,
      });
    }
  });

  prefillInvoiceByLastTransferTask = dropTask(async () => {
    let { context } = this.args;
    let { supplierSnapshot } = context.invoice;
    let transfers = await this.store.query('transfer', {
      organization_id: this.organizationManager.organization.id,
      sort_by: 'next_recursion_date:desc',
      page: 1,
      per_page: 100,
      bankAccounts: '',
      includes: ['beneficiaries'],
    });

    let filteredTransfers = transfers.filter(
      t => t.beneficiary?.get('iban') === supplierSnapshot.iban
    );

    let lastTransfer = filteredTransfers?.[0];
    this.lastTransfer = lastTransfer;

    if (lastTransfer && lastTransfer.notifyByEmail) {
      context.sepaTransfer.set('notifyByEmail', lastTransfer.notifyByEmail);
    }

    if (lastTransfer && lastTransfer.vatRate && context.sepaTransfer.amount) {
      context.sepaTransfer.set('vatRate', lastTransfer.vatRate);
      context.sepaTransfer.set(
        'vatAmount',
        (
          (context.sepaTransfer.amount / (1 + lastTransfer.vatRate / 100) -
            context.sepaTransfer.amount) *
          -1
        ).toFixed(2)
      );
    }
  });

  openEditModalWithoutRoutingTask = dropTask(async () => {
    this.currentModalType = 'edit';
    let supplierId = this.args.context.invoice.supplierSnapshot.id;
    this.supplier = await this.store.findRecord('supplier', supplierId);

    // @ts-expect-error
    this.supplierEditModal = this.modals.open(
      'suppliers/supplier-modal',
      {
        isFullScreenModal: true,
        title: this.intl.t('supplier-invoices.supplier-edition.title'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.save-changes'),
        confirmTask: this.updateSupplierTask,
        supplier: this.supplier,
        preventRedirectOnClose: true,
        onCloseRedirect: this.onCloseRedirect,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
          escapeDeactivates: false,
        },
      }
    );
  });

  updateSupplierTask = dropTask(async () => {
    this.currentModalType = 'new';
    try {
      // @ts-expect-error
      await this.supplier.save();
      this._updateSupplierInvoice();

      let isSupplierCreation = this.currentModalType === 'new';

      this.toastFlashMessages.toastSuccess(
        isSupplierCreation
          ? this.intl.t(
              'supplier-invoices.supplier-invoice-details.add-new-supplier.confirmation-toast'
            )
          : this.intl.t(
              'supplier-invoices.supplier-invoice-details.updated-supplier.confirmation-toast'
            )
      );

      // @ts-expect-error
      this.args.context.invoice.reload();

      this.segment.track(isSupplierCreation ? 'add-supplier_submit' : 'edit-supplier_submit', {
        result: 'success',
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      if (error.errors.some(errorObj => errorObj?.status === '409')) {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.supplier-invoice-details.form.duplicate-supplier-toast')
        );
        this.segment.track('add-supplier_duplicated_toast');
        return;
      }
      // @ts-expect-error
      if (['required', 'invalid'].includes(error.code)) {
        // Handled by the validation system
        return;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      this.segment.track('edit-supplier_submit', { result: 'error' });
    } finally {
      // @ts-expect-error
      this.supplierEditModal.close();
    }
  });

  @action
  openNewModalWithoutRouting() {
    this.supplier = this.store.createRecord('supplier', {
      countryCode: this.organizationManager.organization.legalCountry,
      organizationId: this.organizationManager.organization.id,
    });

    // @ts-expect-error
    this.supplierEditModal = this.modals.open(
      'suppliers/supplier-modal',
      {
        isFullScreenModal: true,
        title: this.intl.t('supplier-invoices.supplier-creation.title'),
        description: this.intl.t('supplier-invoices.supplier-creation.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.add-supplier'),
        confirmTask: this.updateSupplierTask,
        supplier: this.supplier,
        preventRedirectOnClose: true,
        onCloseRedirect: this.onCloseRedirect,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
          escapeDeactivates: false,
        },
      }
    );
  }

  _updateSupplierInvoice() {
    if (!this.supplier) return;
    let invoice = this.args.context.invoice;
    invoice.supplierName = this.supplier.name;
    invoice.supplierSnapshot = {
      id: this.supplier.id,
      name: this.supplier.name,
      iban: this.supplier.iban,
      // @ts-expect-error
      tin: this.supplier.tinNumber,
      email: this.supplier.email,
    };
  }

  _scrollToErrorField() {
    next(() => scrollIntoView('[slot="errorMessage]'));
  }

  _trackContinueCTA() {
    let { invoice, origin, sepaTransfer } = this.args.context;
    let { attachments, amount, reference, beneficiary } = sepaTransfer;

    let hasAmount = Boolean(amount);
    let hasIban = Boolean(beneficiary?.get('iban'));
    let hasReference = Boolean(reference);
    let hasAttachments = Boolean(attachments.length);

    this.segment.track('transfer-sepa_edit_saved', {
      flow: 'byinvoice',
      origin,
      is_einvoice: invoice.isEinvoice,
      amount: hasAmount,
      iban: hasIban,
      reference: hasReference,
      attachments: hasAttachments,
    });
  }

  #createPayLaterDataModel() {
    let { amount, reference } = this.args.context.sepaTransfer;
    let PayLaterValidationModel = this.#createPayLaterValidationModel();

    // @ts-expect-error
    class PayLaterDataModel extends EmberObject.extend(PayLaterValidationModel) {
      // @ts-expect-error
      @service intl;
      // @ts-expect-error
      @service localeManager;

      // @ts-expect-error
      @tracked amount;
      // @ts-expect-error
      @tracked reference;
    }

    // @ts-expect-error
    return PayLaterDataModel.create(getOwner(this).ownerInjection(), { amount, reference });
  }

  #createPayLaterValidationModel() {
    // @ts-expect-error
    let { minTransferAmount, availableCreditAmount } = this.args.context;

    return createPayLaterTransferValidations(
      minTransferAmount,
      availableCreditAmount,
      this.#getValidationMessage.bind(this)
    );
  }

  // @ts-expect-error
  #getValidationMessage(minTransferAmount, availableCreditAmount) {
    // @ts-expect-error
    return (key, value) => {
      if (value < minTransferAmount.value) {
        return this.#getMinimumAmountValidationMessage(minTransferAmount);
      } else if (value > availableCreditAmount.value) {
        return this.#getAvailableCreditValidationMessage(availableCreditAmount);
      }
    };
  }

  // @ts-expect-error
  #getMinimumAmountValidationMessage(minTransferAmount) {
    let formattedAmount = this.intl.formatNumber(minTransferAmount.value, {
      style: 'currency',
      currency: minTransferAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.minimum-amount', {
      amount: formattedAmount,
    });
  }

  // @ts-expect-error
  #getAvailableCreditValidationMessage(availableCreditAmount) {
    let formattedAmount = this.intl.formatNumber(availableCreditAmount.value, {
      style: 'currency',
      currency: availableCreditAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.available-credit', {
      amount: formattedAmount,
    });
  }
}
