export default {
  "container": "Yt",
  "disabled-option": "YM",
  "content": "YP",
  "spinner": "Yl",
  "subtitle": "YX",
  "details-table": "YC",
  "caption": "Yk",
  "tooltip-icon": "YJ",
  "tooltip-icon-right": "Ys",
  "card-monthly-cost": "YF",
  "card-monthly-discounted-cost": "Yg",
  "strikethrough": "YD",
  "purple-text": "YV",
  "budget-label": "YL",
  "card-cost-label": "YT",
  "card-billing-account": "Yz"
};
