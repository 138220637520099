export default {
  "container": "nX",
  "container-content": "nC",
  "content": "nk",
  "underline": "nJ",
  "inline-content": "ns",
  "payment-rate": "nF",
  "payment-subtitle": "ng",
  "grey-text": "nD",
  "earnings-header": "nV",
  "earnings-tooltip": "nL"
};
