export default {
  "details": "Uk",
  "disclaimer": "UJ",
  "data": "Us",
  "confirm": "UF",
  "form": "Ug",
  "textarea": "UD",
  "preview": "UV",
  "form-title": "UL title1",
  "form-subtitle": "UT title2",
  "divider": "Uz",
  "submit": "Ua",
  "doc": "UH",
  "amount-input-error": "UO"
};
