export default {
  "guests-loading": "cRD",
  "header": "cRV",
  "body": "cRL",
  "members": "cRT",
  "title": "cRz caption-bold",
  "placeholder-container": "cRa",
  "icon": "cRH",
  "details": "cRO"
};
