export default {
  "sub-menu": "OM",
  "collapsed": "OP",
  "collapse-left": "Ol",
  "responsive": "OX",
  "expanded": "OC",
  "no-slide": "Ok",
  "visible": "OJ",
  "hidden": "Os",
  "group": "OF",
  "promo-box": "Og",
  "title": "OD",
  "quick-access-padding": "OV",
  "scrolled-top": "OL",
  "scrollable-content": "OT"
};
