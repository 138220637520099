export default {
  "container": "IM",
  "title": "IP",
  "subtitle": "Il",
  "form": "IX",
  "uploader": "IC",
  "submit-button": "Ik",
  "error-message": "IJ",
  "inputs": "Is"
};
