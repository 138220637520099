export default {
  "wrapper": "LV",
  "header": "LL",
  "status": "LT",
  "close-button": "Lz",
  "slug": "La",
  "category": "LH",
  "category-title": "LO",
  "activity-tag-select": "Lm",
  "footer": "Lp"
};
