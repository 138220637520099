export default {
  "wrapper": "cqz input-field mb-4",
  "error": "cqa",
  "inactive": "cqH",
  "hidden-value": "cqO",
  "percentage-sign": "cqm",
  "black": "cqp",
  "input-field": "cqx",
  "disabled": "cqw"
};
