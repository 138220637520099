export default {
  "wrapper": "nf",
  "wrapper-new": "nK",
  "icon-list": "nG",
  "list-item": "nr",
  "box-wrapper": "nb",
  "panel": "ny",
  "panel-top": "nU",
  "list": "nj",
  "container": "nW",
  "column": "nu",
  "icon": "iS",
  "column-right": "ic"
};
