/* import __COLOCATED_TEMPLATE__ from './checkout-form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Disclaimer, Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { loadScript } from 'qonto/utils/load-script';

interface CheckoutFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CheckoutForm extends Component<CheckoutFormSignature> {
  disclaimer: typeof Disclaimer.Block = Disclaimer.Block;

  spinner = Spinner;

  @service declare intl: Services['intl'];
  @service declare theme: Services['theme'];

  frames = null;
  @tracked cardNumberError = null;
  @tracked cvvError = null;
  @tracked expiryError = null;

  @tracked elementsLoaded = [];
  @tracked isPaymentMethodAccepted = false;

  constructor(owner: unknown, args: CheckoutFormSignature['Args']) {
    super(owner, args);
    this.insertFramesTask.perform().catch(ignoreCancelation);
  }
  get texts() {
    // @ts-expect-error
    let { flowType } = this.args;

    if (flowType === 'pay-later-topup') {
      return {
        cardNumberLabel: this.intl.t('financing.pay-later.topup.payment.card-number.label'),
        expirationDateLabel: this.intl.t('financing.pay-later.topup.payment.expiration.label'),
        cvvLabel: this.intl.t('financing.pay-later.topup.payment.cvv.label'),
        CTAText: this.intl.t('btn.confirm'),
      };
    }

    if (flowType === 'payment-activation') {
      return {
        cardNumberLabel: this.intl.t('on-boarding.top-up.checkout.card-number.label'),
        expirationDateLabel: this.intl.t('payment-activation-top-up.payment.expiration.label'),
        cvvLabel: this.intl.t('payment-activation-top-up.payment.cvv.label'),
        CTAText: this.intl.t('btn.confirm'),
      };
    }
    return {
      cardNumberLabel: this.intl.t('payment-activation-top-up.payment.card-number.label'),
      expirationDateLabel: this.intl.t('on-boarding.top-up.checkout.expiration-date.label'),
      cvvLabel: this.intl.t('on-boarding.top-up.checkout.cvv.label'),
      CTAText: this.intl.t('on-boarding.top-up.checkout.cta'),
    };
  }

  insertFramesTask = dropTask(async () => {
    if (macroCondition(!isTesting())) {
      await this.loadFramesTask.perform();
    }

    this._initFrames();
  });

  loadFramesTask = dropTask(async () => {
    // @ts-expect-error
    if (!window.Frames) {
      await loadScript('https://cdn.checkout.com/js/framesv2.min.js');
    }
  });

  _initFrames() {
    // @ts-expect-error
    this.frames = window.Frames;

    // @ts-expect-error
    let { isCurrentThemeDark } = this.theme;

    // @ts-expect-error
    this.frames.init({
      // @ts-expect-error
      publicKey: ENV.checkout.publicKey,
      debug: true,
      // @ts-expect-error
      localization: this.args.locale,
      acceptedPaymentMethods: ['Mastercard', 'Visa'],
      style: {
        base: {
          backgroundColor: isCurrentThemeDark ? '#050505' : '#fff', // --primary-black : --primary-white
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu",
          color: isCurrentThemeDark ? '#f9f9f9' : '#050505', // --gray25 : --primary-black
          fontSize: '14px', // --body-2-font-size
          fontWeight: 'normal', // --body-2-font-weight
          lineHeight: '24px', // --body-2-line-height
          letterSpacing: '0px',
        },
        placeholder: {
          base: {
            color: '#8f8f8f', // --gray500
            textOverflow: 'ellipsis !important',
            opacity: 1,
            letterSpacing: '0px',
          },
        },
      },
    });

    // @ts-expect-error
    this.frames.addEventHandler(this.frames.Events.PAYMENT_METHOD_CHANGED, event => {
      let { isPaymentMethodAccepted } = event;
      let error = !isPaymentMethodAccepted
        ? this.intl.t('payment-activation-top-up.payment.card-number.error')
        : null;

      this.isPaymentMethodAccepted = isPaymentMethodAccepted;
      // @ts-expect-error
      this.cardNumberError = error;
    });

    // @ts-expect-error
    this.frames.addEventHandler(this.frames.Events.FRAME_VALIDATION_CHANGED, event => {
      let { element, isEmpty, isValid } = event;

      let error = isEmpty || !isValid ? this.intl.t('validations.errors.invalid') : null;

      if (element === 'card-number') {
        if (!this.isPaymentMethodAccepted) {
          // @ts-expect-error
          this.cardNumberError = this.intl.t('payment-activation-top-up.payment.card-number.error');
          return;
        } else {
          // @ts-expect-error
          this.cardNumberError = error;
        }
      }

      if (element === 'expiry-date') {
        // @ts-expect-error
        this.expiryError = error;
      }

      if (element === 'cvv') {
        // @ts-expect-error
        this.cvvError = error;
      }
    });

    // @ts-expect-error
    this.frames.addEventHandler(this.frames.Events.FRAME_ACTIVATED, event => {
      // @ts-expect-error
      if (!this.elementsLoaded.includes(event.element)) {
        // @ts-expect-error
        this.elementsLoaded.push(event.element);
      }

      // The event fires once for each IFrame loaded
      if (
        // @ts-expect-error
        this.elementsLoaded.includes('card-number') &&
        // @ts-expect-error
        this.elementsLoaded.includes('expiry-date') &&
        // @ts-expect-error
        this.elementsLoaded.includes('cvv')
      ) {
        // @ts-expect-error
        this.args.onFramesReady?.(event);
      }
    });
  }

  submitCardTask = dropTask(async () => {
    // @ts-expect-error
    if (this.frames.isCardValid()) {
      // @ts-expect-error
      this.frames.enableSubmitForm();
      // @ts-expect-error
      let cardTokenized = await this.frames.submitCard();
      // @ts-expect-error
      return this.args.onSubmitTask.linked().perform(cardTokenized, 'checkout');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckoutForm: typeof CheckoutForm;
  }
}
