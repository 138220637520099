export default {
  "container": "tU",
  "is-sidebar-content": "tj",
  "no-sep": "tW",
  "details": "tu",
  "detail": "MS",
  "name": "Mc",
  "content": "Mq",
  "content-text": "MZ",
  "break-word": "MR",
  "primary": "MQ",
  "section": "Me",
  "infos-container": "MB",
  "infos-disclaimer": "ME",
  "upsell-container": "Md"
};
