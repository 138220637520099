export default {
  "row": "Xl",
  "active": "XX",
  "status": "XC",
  "align-right": "Xk",
  "animated": "XJ",
  "fadein-item": "Xs",
  "cell": "XF body-2",
  "cell-content": "Xg",
  "amount": "XD body-1",
  "subtitle": "XV caption",
  "no-padding": "XL",
  "note": "XT"
};
