export default {
  "step-container": "ccJ",
  "content": "ccs",
  "sticky-panel": "ccF",
  "banner": "ccg",
  "banner-content": "ccD",
  "banner-text": "ccV",
  "banner-image": "ccL",
  "banner-title": "ccT",
  "banner-description": "ccz body-2",
  "link": "cca"
};
