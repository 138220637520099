export default {
  "row": "ND",
  "active": "NV",
  "cell": "NL",
  "empty": "NT",
  "supplier": "Nz",
  "supplier-content": "Na",
  "declined": "NH",
  "total-repayment": "NO body-1",
  "amount": "Nm",
  "supplier-avatar": "Np mr-16",
  "financing-status": "Nx body-2",
  "installments": "Nw body-2",
  "overflow-hidden": "NN",
  "ellipsis": "Nv",
  "completed-on": "No",
  "financed-amount": "Nn body-2"
};
