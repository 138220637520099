export default {
  "row": "lG",
  "active": "lr",
  "status": "lb",
  "align-right": "ly",
  "quick-actions": "lU",
  "cell-approve": "lj",
  "account-select": "lW",
  "hidden": "lu",
  "no-padding": "XS",
  "animated": "Xc",
  "fadein-item": "Xq",
  "cell": "XZ body-2",
  "cell-content": "XR",
  "amount": "XQ body-1",
  "subtitle": "Xe caption"
};
