export default {
  "container": "Gd",
  "content": "GI",
  "buttons": "Gt",
  "genders": "GM",
  "birth-details": "GP",
  "has-error": "Gl",
  "gender-option": "GX",
  "radio-button": "GC"
};
