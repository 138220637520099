/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/connections/sidebar/header';

interface ConnectionsBanksSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConnectionsBanksSidebarComponent extends Component<ConnectionsBanksSidebarSignature> {
  header = Header;

  @service declare intl: Services['intl'];

  get showCTA() {
    // @ts-expect-error
    let { showDisconnect, showReconnect } = this.args;

    return Boolean(showDisconnect) || Boolean(showReconnect);
  }

  get permissions() {
    return [
      this.intl.t('settings.connections.sidebar.qonto-permissions.item1'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item2'),
      this.intl.t('settings.connections.sidebar.qonto-permissions.item3'),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Banks::Sidebar': typeof ConnectionsBanksSidebarComponent;
  }
}
