export default {
  "content": "un",
  "error-message": "ui",
  "wrapper": "uA",
  "banner": "uY",
  "banner-content": "uh",
  "banner-text": "uf",
  "banner-image": "uK",
  "banner-title": "uG",
  "banner-description": "ur body-2",
  "link": "ub",
  "plans-comparison": "uy",
  "plan-box": "uU",
  "arrow": "uj",
  "arrow-icon": "uW",
  "link-downgrade": "uu",
  "stroke-text": "cSS"
};
