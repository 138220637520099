export default {
  "not-active": "Bl",
  "title": "BX",
  "details": "BC",
  "detail": "Bk",
  "text-secondary": "BJ",
  "text-primary-a": "Bs",
  "amounts": "BF",
  "contents-container": "Bg",
  "contents": "BD",
  "menu-item": "BV",
  "archive": "BL",
  "amount-settled": "BT",
  "shadow": "Bz",
  "name": "Ba title-4",
  "tooltip": "BH"
};
