export default {
  "wrapper": "cZH",
  "close-button": "cZO",
  "content-wrapper": "cZm",
  "main": "cZp",
  "main-content": "cZx",
  "disclaimer": "cZw",
  "description": "cZN",
  "mt-26": "cZv",
  "mt-62": "cZo",
  "user-prompt": "cZn",
  "user-prompt-inappropriate-warning": "cZi",
  "logo-preview": "cZA",
  "static-loader": "cZY",
  "loading-state-illustration": "cZh",
  "sticky-panel": "cZf"
};
