export default {
  "header-cell": "lN",
  "header-content": "lv caption-bold",
  "active": "lo",
  "hidden": "ln",
  "empty": "li",
  "align-right": "lA",
  "no-padding": "lY",
  "request-type": "lh",
  "amount": "lf",
  "approval": "lK"
};
